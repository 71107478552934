import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { RequestService } from './services/requestService/request.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LanguageService } from './services/languageService/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  menu = [
    { name: 'gen_home', icon: 'home', link: 'home' },
    { name: 'gen_about', icon: 'info', link: 'about' },
    { name: 'gen_menu', icon: 'restaurant', link: 'menu' },
    { name: 'gen_gallery', icon: 'photo', link: 'gallery' },
    { name: 'gen_staff', icon: 'person', link: 'staff' },
    { name: 'gen_contact', icon: 'phone', link: 'contact' }
  ];



  languages: any = [
    { name: 'DE', id: 'de' },
    { name: 'EL', id: 'el' },
    { name: 'EN', id: 'en' }
  ];


  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  langForm!: FormGroup;
  lang!: string;
  isOpen: boolean = true;
  constructor(private translate: TranslateService, private observer: BreakpointObserver, private request: RequestService, private fb: FormBuilder, private languageService: LanguageService) {
    this.translate.setDefaultLang('en');
  }

  switchLanguage(event: Event): void {
    this.languageService.setLanguage(this.langForm.value.lang);
    location.reload();
  }
  ngOnInit(): void {
    const lang = this.languageService.getLanguage(true);
    this.lang = lang;
    this.langForm = this.fb.group({
      lang: lang
    });
    this.checkIsOpen();
  }

  checkIsOpen(): void {
    this.request.getRequest({ url: '/isOpen' }).then((resp: any) => {
      if (resp.status) {
        this.isOpen = resp.isOpen;
        this.languageService.setCadingStatus(this.isOpen);
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.observer.observe(['(max-width: 800px)']).subscribe((resp: any) => {
        if (resp?.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });
    }, 0);
  }
}
