import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './routes/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BottomComponent } from './components/bottom/bottom.component';
import { RecaptchaModule } from "ng-recaptcha";
import { ContactComponent } from './routes/contact/contact.component';
import { AboutComponent } from './routes/about/about.component';
import { MenuComponent } from './routes/menu/menu.component';
import { GalleryComponent } from './routes/gallery/gallery.component';
import { StaffComponent } from './routes/staff/staff.component';

import { SlideImageComponent } from './components/slide-image/slide-image.component';
// Material
import { ReactiveFormsModule } from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { SpinnerComponent } from './components/spinner/spinner.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BottomComponent,
    ContactComponent,
    AboutComponent,
    MenuComponent,
    GalleryComponent,
    StaffComponent,
    SpinnerComponent
  ],
  providers: [],
  bootstrap: [AppComponent],
  imports: [
    CarouselModule.forRoot(),
    MatDialogModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatSidenavModule,
    MatButtonModule,
    MatCardModule,
    LayoutModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    BrowserModule,
    RecaptchaModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SlideImageComponent,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CarouselModule.forRoot(),
  ],
})

export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
