import { Component, HostListener, SimpleChanges } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, bounceIn, fadeInRight, fadeInLeft, bounceInUp, bounceInDown, fadeIn } from 'ng-animate';
import { RequestService } from 'src/app/services/requestService/request.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.less'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),
    trigger('fadeInRight', [transition('* => *', useAnimation(fadeInRight, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('fadeIn', [transition('* => *', useAnimation(fadeIn, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('fadeInLeft', [transition('* => *', useAnimation(fadeInLeft, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('bounceInDown', [transition('* => *', useAnimation(bounceInDown, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('bounceInUp', [transition('* => *', useAnimation(bounceInUp, {
      params: { timing: 1, delay: 0 }
    }))]),
  ],
})
export class AboutComponent {
  constructor(private request: RequestService) { }
  animate: any;
  screen: number = 800;
  about = new Array();
  ngOnInit(): void {
    this.screen = window.screen.width;
    setTimeout(() => {
      this.request.getRequest({ url: "/getAboutImage" }).then((resp: any) => {
        if (resp.status) {
          this.about = resp.data.data.map((item: any) => {
            return {
              id: item.id,
              img: item.path,
              imgPath: resp.data.imgPath
            }
          });
        }
      })
    }, 500);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screen = event.target.innerWidth;
  }
}
