import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SlideImageComponent } from 'src/app/components/slide-image/slide-image.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }
  enter: string = '300ms';
  exit: string = '300ms';


  openSlideImage(data?: any): void {

    const dialogRef = this.dialog.open(SlideImageComponent, {
      width: '100vw',
      maxWidth: '100vw',
      panelClass: 'generic-dialog',
      data: data,
      autoFocus: false,
      enterAnimationDuration: this.enter,
      exitAnimationDuration: this.exit
    });
    dialogRef.afterClosed().subscribe(res => {
      // console.log(res);
    })
  }
}
