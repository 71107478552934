import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GalleryComponent, GalleryItem, GalleryModule, ImageItem } from 'ng-gallery';
import { LanguageService } from 'src/app/services/languageService/language.service';

@Component({
  selector: 'app-slide-image',
  templateUrl: './slide-image.component.html',
  styleUrls: ['./slide-image.component.less'],
  standalone: true,
  imports: [GalleryModule]
})
export class SlideImageComponent {
  constructor(public dialogRef: MatDialogRef<SlideImageComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private languageService: LanguageService) { }
  images: GalleryItem[] = [];
  lang: string = this.languageService.getLanguage();
  ngOnInit() {
    this.data.gallery.forEach((image: string) => {
      this.images.push(new ImageItem({ src: image, thumb: image }))
    });
  }
}
