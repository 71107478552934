<div *ngIf="staff.length>0 else spinner">
    <div class="menu-title text-center center stuff-box">
        <div class="overlay" [@bounce]="animate">
            <h2 class="" [innerHTML]="'gen_staff' | translate">Menu</h2>
        </div>
    </div>
    <div class="stuff-box">
        <div class="container">
            <div class="row">
                <div class="col-md-4 offset-md-1 col-sm-6" [@bounceIn]="animate">
                    <div class="our-team">
                        <div class="pic">
                            <img [src]="staff[0]?.img ? staff[0].imgPath + staff[0].img :'assets/images/staff-00.jpg'" class="" alt="staff">
                            <ul class="social">
                                <li>
                                    <!-- <a  class="fa fa-instagram"></a> -->
                                </li>
                            </ul>
                        </div>
                        <div class="team-content">
                            <h3 class="title">Vasilis</h3>
                            <span class="post wow fadeIn">{{ 'team_roaster' | translate}}</span>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 offset-md-1 col-sm-6" [@bounceIn]="animate">
                    <div class="our-team">
                        <div class="pic">
                            <img [src]="staff[1]?.img ? staff[1].imgPath + staff[1].img :'assets/images/staff-00.jpg'" class="" alt="staff">
                            <ul class="social">
                                <li>
                                    <!-- <a  class="fa fa-instagram"></a> -->
                                </li>
                            </ul>
                        </div>
                        <div class="team-content">
                            <h3 class="title">Tasos</h3>
                            <span class="post">{{ 'team_roaster' | translate}}</span>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>

</div>
<ng-template #spinner>
    <spinner></spinner>
</ng-template>