import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private translate: TranslateService) { }
  language: string = 'de';
  cadinIsOpen: boolean = true;
  setLanguage(language: string): void {
    this.language = language;
    this.translate.use(language);
    localStorage.setItem('lang', this.language);
  }

  getLanguage(set?: boolean): string {
    const lang = localStorage.getItem('lang');
    const language = lang ? lang : this.language;
    if (set) this.translate.use(language);
    return language;
  }

  setCadingStatus(isOpen: boolean): void {
    this.cadinIsOpen = isOpen;
  }

  isOpen(): boolean{
    console.log("CALL ME", this.cadinIsOpen)
    return this.cadinIsOpen;
  }
}
