<div class="gallery" *ngIf="boxes.length>0 else spinner">
    <div class="menu-title text-center center">
        <div class="overlay" [@bounce]="animate">
            <h2 class="" [innerHTML]="'gen_gallery' | translate">Menu</h2>
        </div>
    </div>
    <div class="container">
        <div class="row" [@fadeIn]="animate">
            <div class="col-md-4 col-xs-12 mb-4" *ngFor="let item of boxes; let i=index" [hidden]="item.gallery == 0">
                <div class="category-box" (click)="openSlideImage(item)">
                    <div class="big-img mb3">
                        <img [src]="item?.gallery[0] ?  item.gallery[0] : 'assets/images/gallery_not_found.jpg'" alt="">
                    </div>
                    <div class="mini-img">
                        <div class="triple">
                            <div class="fix-size-min-img left">
                                <img [src]="item?.gallery[1] ?  item.gallery[1] : 'assets/images/gallery_not_found.jpg'" alt="">
                            </div>
                            <div class="fix-size-min-img middle">
                                <img [src]="item?.gallery[2] ?  item.gallery[2] : 'assets/images/gallery_not_found.jpg'" alt="">
                            </div>
                            <div class="fix-size-min-img right">
                                <img [src]="item?.gallery[3] ? item.gallery[3] : 'assets/images/gallery_not_found.jpg'" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title text-center">
                    <div class="name">{{item.name[lang]}}</div>
                    <div class="album">{{item.gallery.length}} Photos</div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #spinner>
    <spinner></spinner>
</ng-template>