<div class="container">
    <div class="row">
        <div class="col-lg-4 text-center">
            <div class="footer-widget pr-lg-5 pr-0">
                <img [@fadeInLeft]="animate" src="assets/images/greek-spot-logo.jpg" class="img-fluid-logo-footer footer-logo mb-3  bounceIn" alt="Foodtruck greek spot">
                <p [@fadeInLeft]="animate">{{'footer_subtitle' | translate}}</p>
            </div>
        </div>
        <div class="col-lg-4 text-center">
            <div class="footer-widget px-lg-5 px-0">
                <h4 class="bounceIn" [@bounceIn]="animate">{{'gen_open_hour' | translate}}</h4>
                <ul class="list-unstyled open-hours" *ngFor="let item of openHours">
                    <li class="d-flex justify-content-between" class=" fadeInLeft" style="font-style: italic;" [@bounce]="animate">
                        <span> <span class="date-style">{{item.date[lang]}}</span> <i class="fa fa-long-arrow-right"></i> </span>
                        <span class=" fadeInRight " [@bounce]="animate"> {{ item.hour }} <i class="fa fa-clock" style="margin-left: 2px;"></i>
                        <p class=" fadeInLeft" [@bounce]="animate"><i class="fa fa-map-marker " style="color:red "></i> {{item.location}}</p>
                        </span>
                    </li>
                </ul>
            </div>

        </div>
        <div class="col-lg-4 ">
            <div class="footer-widget pl-lg-5 pl-0 text-center ">
                <h4 class=" bounceIn" [@bounceIn]="animate">{{'gen_newsletter' | translate}}</h4>
                <p class=" fadeInLeft" [@fadeInRight]="animate">{{ 'newsletter_sub' | translate}}</p>
                <form [formGroup]="formGroup">
                    <div class="form-group  fadeInRight">
                        <input type="email " class="form-control " formControlName="email" placeholder="{{ 'email_placeholder' | translate}} ">
                    </div>
                    <div class="mt-2">
                        <re-captcha class="g-recaptcha" (resolved)="resolved($event)" (errored)="onError($event)" errorMode="handled" siteKey="{{key}}"></re-captcha>
                    </div>
                    <button class="btn btn-primary mt-3" style="margin: 0px 41.5% 10px; " (click)="sendNewsLetter()" [disabled]="!formGroup.valid">{{'gen_submit' | translate }}</button>
                </form>
            </div>

        </div>
        <div class="col-md-12 d-flex align-items-center ">
            <p class="mx-auto text-center mb-0 "> &copy; {{ 'copyright' | translate }} 2022, <span class="themeColor ">GREEK SPOT</span></p>
        </div>
    </div>

</div>