<!-- <div>
    <app-top-bar></app-top-bar>
    <router-outlet></router-outlet>
    <app-bottom></app-bottom>
</div> -->
<div class="place-open-close">
    <img [ngStyle]="{'left': sidenav.mode === 'side' ? '200px' : '0' }" class="close-open-image" *ngIf="!isOpen" src="../assets/images/openclose/closetag-{{lang}}.png" alt="greek spot closed">
    <!-- <img class="close-open-image" ng-if="showCurrentLocation && !vacationTime" ng-src="images/openclose/opentag-{{lang}}.png" alt="greek spot open"> -->
</div>
<mat-toolbar>
    <button mat-icon-button *ngIf="sidenav.mode==='over'" (click)="sidenav.toggle()">
        <mat-icon *ngIf="!sidenav.opened">menu</mat-icon>
        <mat-icon *ngIf="sidenav.opened">close</mat-icon>
    </button>
    <header>
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-sm-4 col-xs-12" *ngIf="sidenav.mode === 'side'">
                    <p><i class="fa fa-phone"></i><span> </span><a href="tel:+{{'mobile_number' | translate}}">{{'mobile_number' | translate}}</a></p>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-12" *ngIf="sidenav.mode === 'side'">
                    <p><i class="fa fa-envelope"></i><span> </span><a href="mailto:angelidis@greekspot.ch">{{ 'email' |
                            translate}}</a></p>
                </div>
                <div class="col">
                    <ul class="social-icon">
                        <li><span>{{ 'title_follow_us' | translate}}</span></li>
                        <li>
                            <a href="https://www.facebook.com/profile.php?id=100089363316525" target="_blank" class="fa fa-facebook"></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/greekspot.ch/" target="_blank" class="fa fa-instagram"></a>
                        </li>
                        <li [formGroup]="langForm">
                            <select formControlName="lang" class="selector-language" (change)="switchLanguage($event)" [value]="lang">
                                <option [value]="language.id" *ngFor="let language of languages">{{language.name}}
                                </option>
                            </select>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</mat-toolbar>

<mat-sidenav-container>
    <mat-sidenav #sidenav="matSidenav" [ngClass]="{'mr-top-0': sidenav.mode === 'side'}">
        <div class="logo">
            <img src="assets/images/greek-spot-logo.jpg" alt="">
        </div>
        <button mat-button class="menu-button" *ngFor="let item of menu; let i=index" [routerLink]="item.link" routerLinkActive="isActive" (click)=" sidenav.mode ==='over' ? sidenav.close() : ''">
            <div class="menu-sidebar">
                <mat-icon>{{item.icon}}</mat-icon>
                <span>{{item.name | translate}}</span>
            </div>
        </button>
    </mat-sidenav>
    <mat-sidenav-content style="overflow-x: hidden; margin: auto;">
        <div class="content mr-top-0" [ngClass]="{'mr-left': sidenav.mode === 'side'}">
            <!-- Main content -->
            <router-outlet></router-outlet>
            <app-bottom></app-bottom>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>