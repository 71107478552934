import { trigger, transition, useAnimation } from '@angular/animations';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { bounce, bounceIn, fadeInRight, fadeIn, fadeInLeft, bounceInDown, bounceInUp } from 'ng-animate';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { LanguageService } from 'src/app/services/languageService/language.service';
import { RequestService } from 'src/app/services/requestService/request.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),
    trigger('fadeInRight', [transition('* => *', useAnimation(fadeInRight, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('fadeIn', [transition('* => *', useAnimation(fadeIn, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('fadeInLeft', [transition('* => *', useAnimation(fadeInLeft, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('bounceInDown', [transition('* => *', useAnimation(bounceInDown, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('bounceInUp', [transition('* => *', useAnimation(bounceInUp, {
      params: { timing: 1, delay: 0 }
    }))]),
  ],
})
export class ContactComponent {
  animate: any;
  location: string = "gen_closed";
  key: string = environment.apiKey;
  formGroup!: FormGroup;
  constructor(private request: RequestService, private translate: TranslateService, private languageService: LanguageService, private formBuilder: FormBuilder) { }
  lang: string = this.languageService.getLanguage();
  ngOnInit(): void {
    setTimeout(() => {
    this.formGroup = this.formBuilder.group({
      fullname: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      phone: ['', Validators.required],
      message: ['', Validators.required],
      key: ['', Validators.required]
    })
    this.request.getRequest({ url: '/getOpenHours' }).then((resp: any) => {
      if (resp.status) {
        resp.data.forEach((item: any) => {
          if (item.date_number == new Date().getDay()) {
            this.location = item.location;
          }
        });
      }
    });            
    }, 500);
  }

  openGoogleMaps(loc: string): void {
    if (this.location == "gen_closed") return;
    // Open Google maps with location
    window.open('https://www.google.com/maps/search/' + loc + ',Bern');
  }

  public resolved(captchaResponse: any): void {
    this.formGroup.controls['key'].setValue(captchaResponse);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  sendMail(): void {
    if(this.formGroup.valid){
      this.request.postRequest({ url: '/sendEmail', req: this.formGroup.value }).then((resp: any) => {
        if (resp.status) {
          this.formGroup.reset();
        }
      })
    }
  }

}
