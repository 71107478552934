<section id="contact" *ngIf="formGroup else spinner">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="wow bounceIn" [@bounceIn]="animate">{{ 'gen_contact' | translate}} <span>GREEK SPOT</span></h2>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 wow fadeInLeft">
                <form [formGroup]="formGroup">
                    <label [@fadeIn]="animate">{{'contact_name' | translate}}</label>
                    <input [@fadeIn]="animate" name="fullname" type="text" class="form-control" id="fullname" formControlName="fullname">

                    <label [@fadeIn]="animate">{{'contact_email' | translate}}</label>
                    <input [@fadeIn]="animate" name="email" type="email" class="form-control" id="email" formControlName="email">
                    <label [@fadeIn]="animate" style="text-transform: uppercase;">{{'gen_mobile' | translate}}</label>
                    <input [@fadeIn]="animate" name="phone" type="tel" class="form-control" id="phone" formControlName="phone">

                    <label [@fadeIn]="animate">{{'contact_message' | translate}}</label>
                    <textarea [@fadeIn]="animate" name="message" rows="4" class="form-control" id="message" formControlName="message"></textarea>
                    <div>
                        <re-captcha class="g-recaptcha" (resolved)="resolved($event)" (errored)="onError($event)" errorMode="handled" siteKey="{{key}}"></re-captcha>
                    </div>
                    <button [@bounce]="animate" type="submit" class="form-control" (click)="sendMail()" [disabled]="!formGroup.valid">{{'gen_submit' |
                        translate}}</button>
                </form>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 wow fadeInRight" data-wow-offset="50" data-wow-delay="0.6s">
                <address>
                    <p class="address-title" [@bounceIn]="animate">{{'contact_our_address' | translate}}</p>
                    <span [@fadeInRight]="animate">{{'contact_our_address_sub' | translate}}</span>
                    <p [@fadeInRight]="animate"><i class="fa-box fa fa-phone"></i> {{ 'mobile_number' | translate}}</p>
                    <p [@fadeInRight]="animate"><i class="fa-box fa fa-envelope"></i> {{ 'email' | translate}}</p>
                    <p [@fadeInRight]="animate" class="d-inline-block" style="font-style: italic; cursor: pointer;"
                        (click)="openGoogleMaps(location)"><i class=" fa-box fa fa-map-marker"></i>{{ location |
                        translate}} <i class="fa fa-hand-pointer" style="font-size: larger; color:cadetblue"
                            *ngIf="location!='gen_closed'"> {{'gen_find_us' | translate}}</i></p>
                </address>
                <ul class="social-icon" [@fadeIn]="animate">
                    <li>
                        <h4 [innerHTML]="'title_follow_us' | translate">You can follow us</h4>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/people/greekspotch/100089363316525/" target="_blank" class="fa fa-facebook"></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/greekspot.ch/" target="_blank" class="fa fa-instagram"></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<ng-template #spinner>
    <spinner></spinner>
</ng-template>