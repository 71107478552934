<div class="home-view" *ngIf="(description || hasAnnounce) else spinner">
    <div style="border-style: solid; border-width: 5px; border-radius: 5px;" width="100%" class="mb-3" *ngIf="hasAnnounce">
        <img style="width: 100%;" src="../../../assets/images/giphy.gif" alt="">
        <div class="description-carousel announce-img d-md-block description-carousel">
            <div class="menu-title text-center center">
                <div class="overlay">
                    <h2 class="" [innerHTML]="'title' | translate">Menu</h2>
                </div>
            </div>
            <p style="font-size: 14px;max-height: 50px; overflow-y: auto;" [innerHTML]="textAnnounce"></p>
        </div>
    </div>
    <div style="border-style: solid; border-width: 5px; border-radius: 5px;" width="100%" *ngIf="!hasAnnounce">
        <carousel [isAnimated]="true" [@fadeIn]="animate">
            <slide *ngFor="let image of carousel; let i=index">
                <img src="{{image.img}}" alt="first slide" style="display: block; width: 100%; height: 500px; object-fit: cover;">
                <div class="carousel-caption  d-md-block description-carousel ">
                    <div class="menu-title text-center center">
                        <div class="overlay">
                            <h2 class="" [innerHTML]="'title' | translate">Menu</h2>
                        </div>
                    </div>
                    <p style="max-height: 100px; overflow-y: auto;" *ngIf="description">{{description[0].name[lang]}}</p>
                </div>
            </slide>
        </carousel>
    </div>
    <!-- </div>
        </div> -->
    <div class="services">
        <div class="container">
            <div class="row margin-bot-rem" *ngIf="location != 'gen_closed' && !hasAnnounce && isOpen">
                <div class="box-current-place" [@bounce]="animate" (click)="openGoogleMaps(location)">
                    <h3 class="text-center" [innerHTML]="'current_location' | translate"></h3>
                    <div class="text-center">
                        <h3 class="d-inline-block" style="font-style: italic; cursor: pointer; text-align: center;"><i class=" fa-box fa fa-map-marker"></i>{{ location | translate}} <i class="fa fa-hand-pointer" style="font-size: larger; color:cadetblue"> {{'gen_find_us' | translate}}</i></h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-xs-12 mb-4">
                    <div class="box-event ">
                        <div class="food-truck">
                            <img [@fadeInLeft]="animate" src="assets/images/food-truck-no-bg.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-xs-12 mb-4">
                    <div class="box-event ">
                        <div class="catering">
                            <img [@bounce]="animate" src="assets/images/catering-no-bg.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-xs-12 mb-4">
                    <div class="box-event ">
                        <div class="festival">
                            <img [@fadeInRight]="animate" src="assets/images/festival-no-bg.png" alt="">
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #spinner>
    <spinner></spinner>
</ng-template>