<div class="container mb-3" *ngIf="about.length>0 else spinner">
    <div>
        <div class="row">
            <div class="col-xs-12 col-md-6  wow fadeInUp" [@bounceIn]="animate" style="visibility: visible;">
                <div class="about-img" [ngClass]="{'margin-fix': screen<768}">
                    <img [src]="about[0]?.img ? about[0].imgPath + about[0].img :'assets/images/kantina.jpg'" class="img-responsive " alt="about us and company">
                </div>
            </div>
            <div class="col-xs-12 col-md-6 ">
                <div class="about-text ">
                    <h2 class="about-title wow bounceIn " [@bounceIn]="animate" [innerHtml]=" 'about_title'| translate ">Our Restaurant</h2>
                    <h3 [innerHtml]=" 'about_name' | translate " class="wow bounceIn ">The name is Greek Spot</h3>
                    <hr class="line-about wow fadeIn " [@fadeIn]="animate">
                    <p class="wow fadeIn " [@fadeIn]="animate" [innerHtml]=" 'about_company' | translate ">Greek spot is a street food company</p>
                    <!-- <h3>Awarded Chefs</h3> -->
                    <p class="wow fadeInLeft " [@fadeIn]="animate" [innerHtml]=" 'about_dream' | translate ">We come from Greece and already know greek cook</p>
                    <p class="wow fadeInRight " [@fadeIn]="animate" [innerHtml]=" 'about_story' | translate ">about story
                    </p>
                    <p class="wow fadeInLeft " [@fadeIn]="animate" [innerHtml]=" 'about_end' | translate ">last</p>
                    <h3 class="wow bounceIn " [@bounceIn]="animate" [innerHtml]=" 'about_signature' | translate ">Our signature
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #spinner>
    <spinner></spinner>
</ng-template>