import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment.development';


@Injectable({
  providedIn: 'root'
})
export class RequestService {
  constructor(private http: HttpClient, private _snackBar: MatSnackBar) { }

  // Sent GET Request
  getRequest(params: any) {
    return new Promise(resolve => {
      this.http.get<any>(environment.apiUrl + params.url, { withCredentials: true }).
        subscribe((data: any) => {
          resolve(data);
        }, error => {
          resolve(error);
          if(error?.error?.message)
            this.openSnackBar(false,error.error.message);
        });
    });
  };

  // Sent POST Request
  postRequest(params: any) {
    return new Promise(resolve => {
      this.http.post<any>(environment.apiUrl + params.url, params.req, { withCredentials: true })
        .subscribe(data => {
          resolve(data);
          this.openSnackBar(true,data.description);
        }, error => {
          resolve(error);
          if(error?.error?.message)
            this.openSnackBar(false,error.error.message);
        });
    });
  };
  // Create Header for GET Call request
  createKeys(params: any): any {
    let param = '';
    const keys = Object.keys(params);
    keys.forEach((key: string, i: number) => {
      param += key + '=' + params[key];
      if (i < (keys.length - 1)) param += '&';
    });
    return param;
  }

  openSnackBar(type:boolean,text?: string) {
    this._snackBar.open((type == true? "Info:" :"Error:")+ text, 'OK', {
      duration: 5 * 1000,
    });
  }
}
