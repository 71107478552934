import { trigger, transition, useAnimation } from '@angular/animations';
import { Component } from '@angular/core';
import { bounce, bounceIn, fadeInRight, fadeIn, fadeInLeft, bounceInDown, bounceInUp } from 'ng-animate';
import { DialogService } from 'src/app/services/dialogService/dialog.service';
import { LanguageService } from 'src/app/services/languageService/language.service';
import { RequestService } from 'src/app/services/requestService/request.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.less'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),
    trigger('fadeInRight', [transition('* => *', useAnimation(fadeInRight, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('fadeIn', [transition('* => *', useAnimation(fadeIn, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('fadeInLeft', [transition('* => *', useAnimation(fadeInLeft, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('bounceInDown', [transition('* => *', useAnimation(bounceInDown, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('bounceInUp', [transition('* => *', useAnimation(bounceInUp, {
      params: { timing: 1, delay: 0 }
    }))]),
  ],
})
export class GalleryComponent {
  constructor(private dialog: DialogService, private request: RequestService, private languageService: LanguageService) { }
  animate: any;
  boxes: any = new Array();
  lang: string = this.languageService.getLanguage();
  ngOnInit(): void {
    setTimeout(() => {
      this.request.getRequest({ url: '/getCategories' }).then((resp: any) => {
        if (resp.status) {
          resp.data.forEach((category: any) => {
            this.request.getRequest({ url: '/getImages/' + category.id }).then((resp2: any) => {
              this.boxes.push({
                name: {
                  en: category.title_en,
                  el: category.title_el,
                  de: category.title_de
                },
                gallery: resp2.data.imgData.map((img: any) => {
                  return resp2.data.imgPath + img.path
                })
              })
            })
          });
        }
      });
  }, 500);
  }

  openSlideImage(item: any): void {
    if (item.gallery.length > 0)
      this.dialog.openSlideImage(item);
  }
}
