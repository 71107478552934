<!-- Restaurant Menu Section -->
<div id="restaurant-menu" *ngIf="menuFood.length>0 else spinner">
    <div class="menu-title text-center center">
        <div class="overlay">
            <h2 class="" [innerHTML]="'menu_title' | translate">Menu</h2>
            <!-- <hr class="line-about"> -->
            <p class="" [innerHTML]="'menu_subtitle' | translate" [@bounceIn]="animate"></p>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-md-6" *ngFor="let menus of menuFood let i=index">
                <div class="menu-section ">
                    <h2 class="menu-section-title">{{menus.title[lang]}}</h2>
                    <hr class="line-about">
                    <div class="menu-item" *ngFor="let food of menus.menu">
                        <mat-card class="example-card" [@fadeIn]="{value:animate, params:{timing: i+1}}">
                            <mat-card-header>
                                <mat-card-title-group>
                                    <mat-card-title>
                                        <div class="menu-item-name">{{food.name[lang]}}</div>
                                    </mat-card-title>
                                    <mat-card-subtitle class="menu-item-description" [hidden]="food.description[lang] == '-'">{{food.description[lang]}}</mat-card-subtitle>
                                    <div class="food-img">
                                        <img mat-card-md-image src="{{food.img}}">
                                    </div>
                                </mat-card-title-group>
                            </mat-card-header>
                            <mat-card-content></mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ng-template #spinner>
    <spinner></spinner>
</ng-template>