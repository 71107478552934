import { trigger, transition, useAnimation } from '@angular/animations';
import { Component } from '@angular/core';
import { bounce, bounceIn, fadeInRight, fadeIn, fadeInLeft, bounceInDown, bounceInUp } from 'ng-animate';
import { RequestService } from 'src/app/services/requestService/request.service';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.less'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),
    trigger('fadeInRight', [transition('* => *', useAnimation(fadeInRight, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('fadeIn', [transition('* => *', useAnimation(fadeIn, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('fadeInLeft', [transition('* => *', useAnimation(fadeInLeft, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('bounceInDown', [transition('* => *', useAnimation(bounceInDown, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('bounceInUp', [transition('* => *', useAnimation(bounceInUp, {
      params: { timing: 1, delay: 0 }
    }))]),
  ],
})
export class StaffComponent {
  constructor(private request: RequestService) { }
  staff = new Array();
  animate: any;
  ngOnInit(): void {
    setTimeout(() => {
      this.request.getRequest({ url: '/getStaff' }).then((resp: any) => {
        if (resp.status) {
          this.staff = resp.data.data.map((item: any) => {
            return {
              id: item.id,
              img: item.path,
              imgPath: resp.data.imgPath
            }
          });
        }
      })
    }, 500);
  }

}