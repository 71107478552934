import { Component } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, bounceIn, fadeInRight, fadeInLeft } from 'ng-animate';
import { RequestService } from 'src/app/services/requestService/request.service';
import { LanguageService } from 'src/app/services/languageService/language.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bottom',
  templateUrl: './bottom.component.html',
  styleUrls: ['./bottom.component.less'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),
    trigger('fadeInRight', [transition('* => *', useAnimation(fadeInRight, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('fadeInLeft', [transition('* => *', useAnimation(fadeInLeft, {
      params: { timing: 1, delay: 0 }
    }))]),
  ],
})
export class BottomComponent {
  animate: any;
  key: string = environment.apiKey;
  constructor(private request: RequestService, private languageService: LanguageService, private formBuilder: FormBuilder) { }
  formGroup!: FormGroup;
  openHours = new Array();
  lang: string = this.languageService.getLanguage();
  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      key: ['', Validators.required]
    })
    this.request.getRequest({ url: '/getOpenHours' }).then((resp: any) => {

      if (resp.status) {
        this.openHours = resp.data.map((item: any) => {
          return {
            id: item.id,
            hour: item.hour,
            location: item.location,
            date: {
              en: item.date_en,
              el: item.date_el,
              de: item.date_de
            }
          }
        });
      }
    });
  }

  public resolved(captchaResponse: any): void {
    this.formGroup.controls['key'].setValue(captchaResponse);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  sendNewsLetter(): void {
    if(this.formGroup.valid){
      this.request.postRequest({ url: '/sendNewsLetter', req: this.formGroup.value }).then((resp: any) => {
        if (resp.status) {
          this.formGroup.reset();
        }
      })
    }
  }

}
