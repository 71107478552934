import { Component } from '@angular/core';
import { RequestService } from 'src/app/services/requestService/request.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, bounceIn, fadeInRight, fadeInLeft,bounceInUp,bounceInDown, fadeIn } from 'ng-animate';
import { LanguageService } from 'src/app/services/languageService/language.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),
    trigger('fadeInRight', [transition('* => *', useAnimation(fadeInRight, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('fadeIn', [transition('* => *', useAnimation(fadeIn, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('fadeInLeft', [transition('* => *', useAnimation(fadeInLeft, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('bounceInDown', [transition('* => *', useAnimation(bounceInDown, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('bounceInUp', [transition('* => *', useAnimation(bounceInUp, {
      params: { timing: 1, delay: 0 }
    }))]),
  ],
})
export class HomeComponent {
  location: string = "gen_closed";
  isOpen: boolean = true;
  constructor(private request: RequestService, private languageService: LanguageService) { }
  lang: string = this.languageService.getLanguage();
    
  hasAnnounce!: boolean;
  textAnnounce: string = '';
  description!: any;
  carousel = new Array();
  animate: any;
  ngOnInit(): void {
    setTimeout(() => {
    this.isOpen = this.languageService.isOpen();
    this.request.getRequest({ url: '/hasAnnounce' }).then((resp: any) => {
      if (resp.status && resp.hasAnnounce) {
        this.hasAnnounce = resp.hasAnnounce;
        this.textAnnounce = resp.text;
        console.log(this.hasAnnounce)
      }else{
        this.getCarousel();
      }

      this.request.getRequest({ url: '/getOpenHours' }).then((resp: any) => {
        if (resp.status) {
          resp.data.forEach((item: any) => {
            console.log(item)
            if (item.date_number == new Date().getDay()) {
              this.location = item.location;
            }
          });
        }
      });
    });
  }, 500);
  }

  getCarousel(): void {
    this.request.getRequest({ url: '/getCarousel' }).then((resp: any) => {
      if (resp.status) {
        this.carousel = resp.data.imgData.map((data: any) => {
          return {
            img: resp.data.imgPath + data.img
          }
        })
      }
      this.request.getRequest({ url: '/getDescriptionCarousel' }).then((resp: any) => {
        if (resp.status) {
          this.description = resp.data.map((data: any) => {
            return {
              id: data.id,
              name: {
                en: data.text_en,
                el: data.text_el,
                de: data.text_de
              },
              }
            });
        }
      });
    });
  }


  openGoogleMaps(loc: string): void {
    if (this.location == "gen_closed") return;
    // Open Google maps with location
    window.open('https://www.google.com/maps/search/' + loc  + ',Bern');
  }
}
