import { Component } from '@angular/core';
import { RequestService } from 'src/app/services/requestService/request.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, bounceIn, fadeInRight, fadeInLeft, bounceInUp, bounceInDown, fadeIn } from 'ng-animate';
import { LanguageService } from 'src/app/services/languageService/language.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),
    trigger('fadeInRight', [transition('* => *', useAnimation(fadeInRight, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('fadeIn', [transition('* => *', useAnimation(fadeIn, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('fadeInLeft', [transition('* => *', useAnimation(fadeInLeft, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('bounceInDown', [transition('* => *', useAnimation(bounceInDown, {
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('bounceInUp', [transition('* => *', useAnimation(bounceInUp, {
      params: { timing: 1, delay: 0 }
    }))]),
  ],
})
export class MenuComponent {
  animate: any;
  constructor(private request: RequestService, private languageService: LanguageService) { }

  menuFood = new Array();
  data = new Array();
  lang: string = this.languageService.getLanguage();

  ngOnInit(): void {
    setTimeout(() => {   
      this.request.getRequest({ url: '/getMenuCategories' }).then((resp: any) => {
        if (resp.status) {
          resp.data.forEach((category: any) => {
            this.request.getRequest({ url: '/getMenuItems/' + category.id }).then((resp2: any) => {
              if (resp2.data.menuData.length > 0) {
                this.data.push({
                  title: {
                    en: category.title_en,
                    el: category.title_el,
                    de: category.title_de,
                    pos: category.position
                  },
                  menu: resp2.data.menuData.map((item: any) => {
                    return {
                      name: {
                        en: item.title_en,
                        el: item.title_el,
                        de: item.title_de
                      },
                      description: {
                        en: item.description_en,
                        el: item.description_el,
                        de: item.description_de
                      },
                      img: resp2.data.imgPath + item.img
                    }
                  })
                })
              }
            })           
          });
          setTimeout(() => {
            this.menuFood = this.sortArray(this.data);
          },200)          
        }
      });
  }, 500);
  }

  sortArray(array: any): any {
    var temp = 0;
    for (var i = 0; i < array.length; i++) {
      for (var j = i; j < array.length; j++) {
        if (array[j].title.pos < array[i].title.pos) {
          temp = array[j];
          array[j] = array[i];
          array[i] = temp;
        }
      }
    }
    return array;
  }

}
